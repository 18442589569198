<template>
  <div>
    <b-row no-gutters class="playground-p-title ml-5">
      {{ $t('views.playground.steps-descriptions.step2.title') }}
    </b-row>
    <b-row no-gutters class="playground-p-description ml-5">
      <b-col cols="10">
        {{ $t('views.playground.steps-descriptions.step2.content') }}
      </b-col>
      <b-col>
        <d-button
            :text="'general.actions.next-step'"
            :class="'d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title'"
            :icon="btnLoading ? 'fa fa-refresh fa-spin' : 'fa fa-arrow-right'"
            :icon-position="'right'"
            @on:button-click="$emit('on:step-1:validated')"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      btnLoading: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped>
.playground-p-title {
  padding-top: 0px;
  padding-bottom: 15px;
  text-align: left;
  font: normal normal 28px/27px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

.playground-p-description {
  text-align: left;
  font: normal normal 18px/27px Avenir;
  letter-spacing: 0px;
  color: #4D4F5CBC;
  opacity: 1;
}
</style>